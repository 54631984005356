// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-mdx": () => import("./../../../src/pages/chi-siamo.mdx" /* webpackChunkName: "component---src-pages-chi-siamo-mdx" */),
  "component---src-pages-cosa-facciamo-0-mdx": () => import("./../../../src/pages/cosa-facciamo-0.mdx" /* webpackChunkName: "component---src-pages-cosa-facciamo-0-mdx" */),
  "component---src-pages-cosa-facciamo-1-mdx": () => import("./../../../src/pages/cosa-facciamo-1.mdx" /* webpackChunkName: "component---src-pages-cosa-facciamo-1-mdx" */),
  "component---src-pages-cosa-facciamo-2-mdx": () => import("./../../../src/pages/cosa-facciamo-2.mdx" /* webpackChunkName: "component---src-pages-cosa-facciamo-2-mdx" */),
  "component---src-pages-cosa-facciamo-3-mdx": () => import("./../../../src/pages/cosa-facciamo-3.mdx" /* webpackChunkName: "component---src-pages-cosa-facciamo-3-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-mdx": () => import("./../../../src/pages/lavora-con-noi.mdx" /* webpackChunkName: "component---src-pages-lavora-con-noi-mdx" */)
}

